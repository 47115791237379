<template>
  <div class="quiz">
    <div v-if="!isQuiz && !isCreateQuiz" class="begin">
      <div class="header">
        <div class="flex items-center justify-between">
          <div class="title flex text-lg">
            <div class="page-name mr-2 text-black">{{ $t('quizMenu') }}</div>
          </div>
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div class="select-pays">
                <button-base
                  :label="$t('createQuizButton')"
                  @click.native="openCreateQuiz"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div class="body bg-white mt-4 p-8 rounded">
          <div class="loading" :class="{ 'not_empty': isLoading === false }">
            <loading
              :active="isLoading"
              :can-cancel="true"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              loader="dots"
              color="#0B5382"
              background-color="transparent"
            />
          </div>
          <div v-if="nothing && !isLoading" class="nothing text-center py-8 text-xl">
            {{ $t('noQuizCategoryAdded') }}
          </div>
          <div v-if="!isLoading" class="categories">
            <div class="categories-lists flex items-center justify-between flex-wrap w-full">
              <quiz-category-list
                v-for="(item, index) in allCategories"
                :key="index"
                :quiz-category-list="item"
                class="w-1/2 mr-8"
                @click.native="openQuizList(index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- QUIZ LIST PAGE -->
    <is-quiz
      v-if="isQuiz"
      :quiz-category-info="categoryInfo"
      @closeQuizList="closeQuizList"
      @updateQuiz="updateQuiz"
      @updateQuizInfo="updateQuizInfo"
    />
    <!-- CREATE QUIZ PAGE -->
    <create-quiz
      v-if="isCreateQuiz"
      :is-quiz-info="quizInformation"
      @closeCreateQuiz="closeCreateQuiz"
    />
  </div>
</template>

<script>
import ButtonBase from '@/components/helper/add/button-base'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import QuizCategoryList from '@/components/helper/quiz/quiz-category-list'
import http from '@/plugins/http'
import apiRoute from '@/router/api-routes'
import IsQuiz from '@/components/helper/quiz/is-quiz'
import CreateQuiz from '@/components/helper/quiz/create-quiz'
export default {
  name: 'index',
  components: { CreateQuiz, IsQuiz, QuizCategoryList, ButtonBase, Loading },
  data () {
    return {
      isLoading: true,
      fullPage: false,
      nothing: false,
      allCategories: [],
      isQuiz: false,
      categoryInfo: {},
      isCreateQuiz: false,
      quizInformation: {}
    }
  },
  created () {
    this.getAllCategory()
  },
  methods: {
    updateQuizInfo (val) { // GET SELECTED QUIZ INFORMATION
      this.quizInformation = val
    },
    updateQuiz (val) { // UPDATE QUIZ FROM QUIZ LIST
      this.isCreateQuiz = val
      this.isQuiz = false
      this.$store.dispatch('CREATE_NEW_QUIZ', false)
    },
    closeCreateQuiz (value) { // CLOSE CREATE QUIZ PAGE
      this.isCreateQuiz = value
      this.isLoading = true
      this.$store.dispatch('CREATE_NEW_QUIZ', true)
      this.quizInformation = {}
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    openCreateQuiz () { // OPEN CREATE QUIZ PAGE
      this.isCreateQuiz = true
      this.$store.dispatch('CREATE_NEW_QUIZ', true)
    },
    closeQuizList (value) { // CLOSE QUIZ LIST PAGE
      this.isQuiz = value
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    openQuizList (index) { // OPEN QUIZ LIST PAGE
      this.isQuiz = true
      this.categoryInfo = this.allCategories[index]
    },
    getAllCategory () { // GET BLOG CATEGORIES
      http.post(apiRoute.baseURL + apiRoute.getCategoryByType, {
        type: 'QUIZ'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.allCategories = response
        this.allCategories[0].icon = 'ic_aid'
        this.allCategories[1].icon = 'ic_sexual_gender'
        this.allCategories[2].icon = 'ic_transgender'
        this.allCategories[3].icon = 'ic_preso'
        if (this.allCategories.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .quiz {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .body {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    min-height: 70vh;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .quiz-category-list {
    width: 48%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
</style>
