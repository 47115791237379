<template>
  <div class="quiz-category-list">
    <div class="begin">
      <div class="debt border flex items-center justify-between rounded-2xl p-6 mb-8 cursor-pointer">
        <div class="part-1 flex items-center">
          <div class="image mr-8 h-16 w-16 p-4 rounded-lg">
            <svg-icon :name="quizCategoryList.icon" original class="w-8 h8" />
          </div>
          <div class="title text-black font-medium text-lg w-3/4 leading-relaxed">{{ quizCategoryList.label }}</div>
        </div>
        <div class="part-2 mr-8">
          <svg-icon
            name="ic_arrow-right"
            original
            class="w-4 h-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quiz-category-list',
  props: {
    quizCategoryList: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .image {
    background-color: rgba(19, 168, 192, 0.15);
  }
</style>
